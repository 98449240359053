import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import { omit, set } from 'lodash';
import LocalStore from '../localStorage';
// eslint-disable-next-line import/no-cycle
import {
  generateBulkQuerySchema,
  handleResponseForApollo,
  errorLink,
} from '../apolloUtils';

const GRAPHQL_URL = process.env.REACT_APP_REACT_API_URL;
const userToken = LocalStore.getValue('userToken');

const graphqlLink = createHttpLink({
  uri: GRAPHQL_URL,
  headers: {
    authorization: userToken ? `Bearer ${userToken}` : '',
  },
});

const removeTypename = obj => {
  if (Array.isArray(obj)) return obj.map(val => removeTypename(val));

  if (obj !== null && typeof obj === 'object') {
    omit(obj, ['__typename']);
    Object.entries(obj).forEach(([key, val]) =>
      set(obj, key, removeTypename(val))
    );
  }
  return obj;
};

const removeTypenameLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => removeTypename(response))
);

// Create REST link
const restLink = new RestLink({
  uri: process.env.REACT_APP_MS_BROADCAST_URL || '',
  headers: {
    authorization: userToken ? `Bearer ${userToken}` : '',
  },
});

// Create Apollo Client
export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
    resultTransformer: data => removeTypename(data),
  }),
  // restLinks need to order before grapqhLinks
  link: ApolloLink.from([errorLink, removeTypenameLink, restLink, graphqlLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: { errorPolicy: 'all' },
  },
});

const ApolloRequest = async ({ params = {}, type }) => {
  let result = null;
  try {
    switch (type) {
      case 'mutation':
        result = await client.mutate(params);
        break;
      case 'bulkQuery':
        result = await client.query({
          query: generateBulkQuerySchema(params, 'query'),
        });
        break;
      case 'bulkMutation':
        result = await client.mutate({
          mutation: generateBulkQuerySchema(params, 'mutation'),
        });
        break;
      default:
        result = await client.query(params);
        break;
    }
    return handleResponseForApollo(result, type);
  } catch (ex) {
    return handleResponseForApollo({
      data: {
        errors: [
          {
            message: ex.message,
          },
        ],
      },
    });
  }
};

export default ApolloRequest;
