import { get, set, uniq } from 'lodash';
import { CARE_JOURNAL, CARE_JOURNAL_WHITEBOARD } from 'routes/constants';
import SplitReactSDK from 'split.sdk';
import { DIGITAL_SHARED_DISPLAY_ROLE, FEATURE_FLAGS } from './constants';
import { classService } from './msEndpointConstants';

/**
 * Check if the user role is the Digital Shared Display Role.
 * @param {Object} userRole - The user role object.
 * @returns {boolean} - True if the user role is the Digital Shared Display Role, false otherwise.
 */
export const isDigitalSharedDisplayRole = userRole => {
  return get(userRole, 'name') === DIGITAL_SHARED_DISPLAY_ROLE;
};

/**
 * Get levels by teacher id
 * @param {*} teacherId
 * @returns
 */
export const getLevelsByTeacherId = async teacherId => {
  try {
    const res = await classService().get(
      `/class-teacher/get-class-and-level-by-teacher-id/${teacherId}`
    );
    return get(res, 'data.level.data') || [];
  } catch {
    return [];
  }
};

/**
 * Get levelIDs based on the user's role for Split
 * @param {*} role
 * @param {*} levels
 * @param {*} isMFS
 * @returns
 */
export const extractLevelIdsByRoleForSplit = (
  role = '',
  levels = [],
  isMFS = true
) => {
  if (!role) return [];

  const ACCESS_ALLOWED = ['all'];
  const ACCESS_DENIED = ['cj_access_denied'];

  const restrictedCJAccessRoles = [
    'assistant_teacher',
    'Staff',
    'css',
    'Education Development Specialist',
    'Childhood Development Specialist',
    'reader',
    'enrolment_advisor',
    'bc_user',
  ];

  const allowedCJAccessRoles = [
    'senior_teacher',
    'default_principal',
    'centre_support',
    'mfs_hq',
    'tech_super_admin',
    'default_hq',
  ];

  if (restrictedCJAccessRoles.includes(role)) return ACCESS_DENIED;
  if (allowedCJAccessRoles.includes(role)) return ACCESS_ALLOWED;
  if (role === 'default_teacher' && get(levels, 'length') > 0)
    return uniq(levels?.map(lv => lv?.id?.toString()));
  if (role === 'admin') return isMFS ? ACCESS_DENIED : ACCESS_ALLOWED;
  if (role === 'hq_bu_support') return isMFS ? ACCESS_ALLOWED : ACCESS_DENIED;

  return [];
};

/**
 * Check and extract to show / hide the CJ menus based on the treatment
 * @param {*} sideMenus menu object array
 * @returns array of menus
 */
export const extractSideMenuForCJ = (sideMenus = []) => {
  const menus = [...sideMenus];
  const client = get(SplitReactSDK, 'client') || {};
  const attributes = client?.getAttributes?.() || {};
  const treatment =
    client?.getTreatment?.(
      FEATURE_FLAGS.CARE_JOURNAL_PORTAL_ENABLED,
      attributes
    ) || 'off';

  if (['control', 'off'].includes(treatment)) {
    const cJMenuMatcher = menu => {
      return [CARE_JOURNAL, CARE_JOURNAL_WHITEBOARD].includes(`/${menu?.path}`);
    };

    const hasCJMenuIndex = menus.findIndex(m =>
      (m?.submenu || []).some(cJMenuMatcher)
    );
    if (hasCJMenuIndex > -1) {
      set(
        menus,
        `[${hasCJMenuIndex}].submenu`,
        (menus?.[hasCJMenuIndex]?.submenu || [])?.filter(s => !cJMenuMatcher(s))
      );
    }
  }

  return menus;
};
